function App() {
    const imageStyle = {
        width: '80%'
    }

    const appStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#282c34', // Use backgroundColor instead of background-color
        height: '100vh', // Use minHeight instead of min-height
        width: '100vw',
        color: 'white',
        margin: '0px',
        padding: '0px',
        border: '0px'
    }

    return (
        <div style={appStyle}>
            <img className="banner" src="/images/Legal_Logo_Grey.png" alt="LEGAL" style={imageStyle} />
        </div>
    )
}

export default App
